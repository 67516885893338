import React, { FC, useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, Grid, TextField, MenuItem, IconButton } from '@mui/material';
import { RootState, useAppDispatch } from '../../../../redux/store/store';
import { useSelector } from 'react-redux';
import { DecoupeRainageParametersType } from '../../../../redux/types/services/serviceParameters';
import { clearCalculationResult } from '../../../../redux/reducers/calculReducer';
import { calculationRow } from '../../../../redux/actions/calculActions';
import { updateQuoteRow } from '../../../../redux/actions/quotesActions';
import CalculationResultDisplay from '../CalculationResultDisplay';
import { QuoteRowType } from '../../../../redux/types/quotes';
import PopoverDetails from '../../PopoverDetails';
import CancelIcon from '@mui/icons-material/Cancel';

interface DecoupeRainageProps{
  open: boolean;
  row: QuoteRowType;
  quoteId: number;
  handleClose: () => void;
}

const DecoupeRainageModal: FC<DecoupeRainageProps> = ({ open, row, quoteId, handleClose }) => {
  const dispatch = useAppDispatch();

  const result = useSelector((state: RootState) => state.calcul.result);

  const [formValues, setFormValues] = useState<DecoupeRainageParametersType>({
    type: 'decoupeRainage',
    description: row.description || '',
    sheetWidth: row.sheetWidth || 0,
    sheetLength: row.sheetLength || 0,
    sheetsNumber: row.sheetsNumber || 0,
    paperWeight: row.paperWeight || 0,
    typeDecoupe: row.typeDecoupe || '',
    typeSupport: row.typeSupport || '',
    rainage: row.rainage || false,
    price: row.price || 0,
    overloadPrice: row.overloadPrice || 0,
    rowId: row.id || 0,
    serviceId: row.serviceId,
    quoteId: quoteId,
  });
  
  useEffect(() => {
    if (open) {
      dispatch(clearCalculationResult());
    }
  }, [open, dispatch]);

  useEffect(() => {
    if (row.id !== null && quoteId !== null) {
      dispatch(calculationRow({
        quoteId,
        rowId: row.id || 0,
        serviceParameters: formValues
      }))
    }
  }, [formValues, row.id, quoteId, dispatch]);

  const handleChange = (field: string, value: string) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleFieldChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(field, event.target.value);
  };

  const handleSave = () => {
    if (row.id !== null && quoteId !== null) {
      formValues.price = result?.price || 0;
      dispatch(updateQuoteRow({ quoteId, rowId: row.id || 0, serviceParameters: formValues }));
      handleClose();
    }
  };

  return (
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-title"
    aria-describedby="modal-description"
    >
    <Box sx={{
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          position: 'absolute',
          width: '90%',
          maxHeight: '90vh',
          p: 4,
          overflowY: 'auto',
          overflowX: 'hidden',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 1
    }}>
      <Box textAlign="center" mb={2}>
        <Typography variant="h4" gutterBottom>
        Decoupe Rainage
        </Typography>
      </Box>
      <Box sx={{ position: 'absolute', top: 16, right: 16, display: 'flex', alignItems: 'center' }}>
        <PopoverDetails details={result?.details || []} />
        <IconButton aria-label="cancel" onClick={handleClose}>
          <CancelIcon sx={{ color: '#d32f2f' }} />
        </IconButton>
      </Box>
      <Box sx={{ padding: '16px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              select
              label="Type de support"
              value={formValues.typeSupport}
              onChange={handleFieldChange('typeSupport')}
              fullWidth
              size="small"
            >
              {/* Exemple d'options, à adapter selon vos besoins */}
              <MenuItem value="couches">Papiers couchés</MenuItem>
              <MenuItem value="offset">Papiers offset</MenuItem>
              <MenuItem value="creation">Papiers de création</MenuItem>
              <MenuItem value="micro">Micro cannelure</MenuItem>
              <MenuItem value="pvc">PVC</MenuItem>
              <MenuItem value="plat">Carton plat</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description"
              value={formValues.description}
              onChange={handleFieldChange('description')}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Nombre de feuilles"
              value={formValues.sheetsNumber}
              onChange={handleFieldChange('sheetsNumber')}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Largeur"
              size="small"
              value={formValues.sheetWidth}
              onChange={handleFieldChange('sheetWidth')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Longueur"
              size="small"
              value={formValues.sheetLength}
              onChange={handleFieldChange('sheetLength')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Grammage"
              size="small"
              value={formValues.paperWeight}
              onChange={handleFieldChange('paperWeight')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Montant en €"
              value={formValues.overloadPrice}
              onChange={handleFieldChange('overloadPrice')}
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
      </Box>
      {result && <CalculationResultDisplay result={result} />}
      <Box textAlign="center" mt={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{ mr: 2 }}
          disabled={result ? !result.isValid : true}
        >
          Enregistrer
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Annuler
        </Button>
      </Box>
    </Box>
    </Modal>
  );
};

export default DecoupeRainageModal;
